import axios from "axios";
import { http } from "./config";
import { CalendarComponent } from "../components/Calendar/calendar.types";

export type Tags =
  | "init"
  | "sprint"
  | "maraphone"
  | "classic"
  | "thai"
  | "kickboxing"
  | "hatha"
  | "bhakti"
  | "jivamukti"
  | "breaststroke"
  | "freestyle";

// export interface User {
//     id: string,
//     name: string,
//     description: string,
//     tgId: number,
//     subscribers: number,
//     socialNetwork: {
//       tg: string,
//       twitter: string,
//       vk: string,
//       instagram: string,
//     },
//     tags: Tags[],
// }
export interface User {
  id: string;
  telegramId: number;
  subscribers: number[];
  name: string;
  nick: string;
  description: string;
  instagramUrl: string;
  telegramUrl: string;
  materialsUrl?: string;
  calendarUrl: string;
  picturesUrl: string[];
  tags: string[];
}

export interface Leader extends User {
  calendar: CalendarComponent[];
}
export interface Post {
  id: string;
  picturesUrl: string[];
  title: string;
  text: string;
  createdAt: Date | null;
}

export interface Comment {
  id: string;
  tgId: number;
  fullName: string;
  text: string;
  picturesUrl: string[];
  parentCommentId: string | null;
  dateCreated: Date | null;
  replies?: Comment[];
}

export interface Subscribe {
  leadId: string;
  userId: number;
}

export interface Booking {
  success: boolean;
  message: string;
}

const routesConfig = http.createRoutesConfig({
  user: {
    /**
     * Получение лидеров
     *
     * @tags user
     * @name getLeads
     * @request GET:/getLeads
     */
    getLeads: http.createRoute<
      {
        tag: Tags;
      },
      User[]
    >({
      url: `/getLeads`,
      method: "GET",
    }),
    /**
     * Получение полного лидеров
     *
     * @tags user
     * @name getLead
     * @request GET:/getLead
     */
    getLead: http.createRoute<
      {
        id: string;
        timeEnd?: string;
        timeStart?: string;
      },
      {
        lead: Leader | undefined;
      }
    >({
      url: `/getLead`,
      method: "GET",
    }),
    /**
     * Получение полного лидеров
     *
     * @tags user
     * @name getLead
     * @request GET:/getLead
     */
    getLeadEvents: http.createRoute<
      {
        id: string;
        timeEnd: string;
        timeStart: string;
      },
      CalendarComponent[]
    >({
      url: `/lead/events`,
      method: "GET",
    }),
    /**
     * Подспика на лидера
     *
     * @tags user
     * @name subscribe
     * @request POST:/subscribe
     */
    subscribe: http.createRoute<Subscribe, void>({
      url: `/subscribe`,
      method: "POST",
    }),
    /**
     * Отписка от лидера
     *
     * @tags user
     * @name subscribe
     * @request POST:/subscribe
     */
    unsubscribe: http.createRoute<Subscribe, void>({
      url: `/unsubscribe`,
      method: "POST",
    }),
    /**
     * Проверка подписки
     *
     * @tags user
     * @name checkSubscribe
     * @request POST:/checkSubscribe
     */
    checkSubscribe: http.createRoute<Subscribe, { isSubscribed: boolean }>({
      url: `/checkSubscribe`,
      method: "POST",
    }),
    /**
     * Бронь
     *
     * @tags user
     * @name booking
     * @request POST:/booking
     */
    booking: http.createRoute<
      {
        leadTgId: number;
        eventId: string;
        tgId: number;
      },
      Booking
    >({
      url: "/booking",
      method: "POST",
    }),
    calendarUpdate: http.createRoute<
      {
        calendarLogin: string;
        calendarPassword: string;
        calendarCaldav: string;
        tgId: number;
      },
      void
    >(({ tgId, calendarLogin, calendarPassword, calendarCaldav }) => ({
      url: `/user/${tgId}/calendar`,
      method: "PUT",
      data: { calendarLogin, calendarPassword, calendarCaldav },
    })),
    /**
     * Отмена брони
     *
     * @tags user
     * @name booking
     * @request POST:/unbooking
     */
    unbooking: http.createRoute<
      {
        leadTgId: number;
        eventId: string;
        tgId: number;
      },
      Booking
    >({
      url: "/unbooking",
      method: "POST",
    }),
  },
  post: {
    /**
     * Получение полного поста
     *
     * @tags post
     * @name getPost
     * @request GET:/getPost
     */
    getPost: http.createRoute<
      {
        id: string;
      },
      {
        post: Post | undefined;
        comments: Comment[];
      }
    >({
      url: `/getPost`,
      method: "GET",
    }),
    /**
     * Получение дополнительных комментариев
     *
     * @tags post
     * @name getComments
     * @request GET:/getComments
     */
    getComments: http.createRoute<
      {
        id: string;
        from: Date;
      },
      Comment[]
    >({
      url: `/getComments`,
      method: "GET",
    }),
    /**
     * Написание комментария
     *
     * @tags post
     * @name comment
     * @request POST:/booking
     */
    comment: http.createRoute<
      {
        parentCommentId: string | null;
        text: string;
        tgId: number;
        fullName: string;
        id: string;
      },
      Comment
    >({
      url: "/comment",
      method: "POST",
    }),
    /**
     * Удаление комментарие
     *
     * @tags post
     * @name comment
     * @request POST:/booking
     */
    deleteComment: http.createRoute<
      {
        commId: string;
      },
      Comment
    >({
      url: "/deleteComment",
      method: "POST",
    }),
    /**
     * Редактирование комментарие
     *
     * @tags post
     * @name comment
     * @request POST:/booking
     */
    editComment: http.createRoute<
      {
        commId: string;
        text: string;
      },
      Comment
    >({
      url: "/editComment",
      method: "POST",
    }),
  },
});

// mocks data
// let mockedUsers: User[] = [
//     {
//       id: '1',
//       name: 'John Doe',
//       description: 'A software developer with a passion for learning new technologies.',
//       telegramId: 123456789,
//       telegramUrl: '@john_doe',
//       instagramUrl: 'john_doe_insta',
//       subscribers: [100],
//       tags: ['maraphone'],
//       calendarUrl: '',
//       picturesUrl: [''],
//       nick: ''
//     },
//     {
//       id: '2',
//       name: 'Jane Smith',
//       description: 'A digital marketer who loves creating engaging content.',
//       telegramId: 987654321,
//       telegramUrl: '@jane_smith',
//       instagramUrl: 'jane_smith_insta',
//       subscribers: [1000],
//       tags: ['hatha'],
//       calendarUrl: '',
//       picturesUrl: [''],
//       nick: ''
//     }
// ];

// let mockedPost: {
//   post: Post | undefined,
//   comments: Comment[],
// } =
//   {
//     post: {
//       id: '1',
//       title: 'Это мой титл',
//       text: 'Это мой текст, он может быть очень большой, в этом то и суть, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла, бла',
//       picturesUrl: [
//         "https://www.icegif.com/wp-content/uploads/2022/01/icegif-982.gif",
//         "https://gifdb.com/images/high/emoji-fading-away-nothing-to-see-here-xb6lz97d522wdjk0.webp",
//       ],
//       createdAt: new Date('2024-06-18T21:45:39.187Z'),
//     },
//     comments: [
//       {
//         id: '1',
//         tgId: 1,
//         fullName: 'Моё имя',
//         text: 'Мне так не нравится этот пост',
//         picturesUrl: [
//           "https://www.icegif.com/wp-content/uploads/2022/01/icegif-982.gif",
//           "https://gifdb.com/images/high/emoji-fading-away-nothing-to-see-here-xb6lz97d522wdjk0.webp",
//         ],
//         parentCommentId: null,
//         replies: [],
//         createdAt: new Date('2024-06-19T21:45:39.187Z'),
//       },
//       {
//         id: '2',
//         tgId: 1,
//         text: 'Мне так не нравится этот пост 2',
//         fullName: 'Моё имя 2',
//         picturesUrl: [
//           "https://gifdb.com/images/high/emoji-fading-away-nothing-to-see-here-xb6lz97d522wdjk0.webp",
//         ],
//         parentCommentId: null,
//         replies: [
//           {
//             id: '21',
//             tgId: 1,
//             fullName: 'Моё имя',
//             text: 'Мне так не нравится этот пост',
//             picturesUrl: [
//               "https://www.icegif.com/wp-content/uploads/2022/01/icegif-982.gif",
//               "https://gifdb.com/images/high/emoji-fading-away-nothing-to-see-here-xb6lz97d522wdjk0.webp",
//             ],
//             parentCommentId: '2',
//             replies: [],
//             createdAt: new Date('2024-06-19T21:45:39.187Z'),
//           }
//         ],
//         createdAt: new Date('2024-06-19T21:46:50.175Z'),
//       }
//     ]
//   };

// let addComment: Comment = {
//     id: '3',
//     tgId: 2,
//     text: 'Мне так не нравится этот пост 3',
//     picturesUrl: [
//       "https://gifdb.com/images/high/emoji-fading-away-nothing-to-see-here-xb6lz97d522wdjk0.webp",
//     ],
//     createdAt: new Date('2024-06-19T21:48:07.454Z'),
//     parentCommentId: null,
//     replies: [],
//     fullName: 'Моё имя add',
//   };

// routesConfig.mocks({
//     post: {
//       getPost: {
//         response: ({ id }) => {
//           return mockedPost
//         },
//       },
//       getComments: {
//         response: ({ id, from }) => [{ ...addComment, createdAt: Date.now(), id: '4' } as unknown as Comment],
//       },
//       comment: {
//         response: ({ id, tgId }) => ({ ...addComment })
//       }
//     },
//     user: {
//         getLeads: {
//             response: ({ tag } ) => mockedUsers.filter(user => user.tags.includes(tag) || tag === 'init'),
//         },
//         getLead: {
//             response: ({ id }) => {
//               const lead = mockedUsers.find(user => user.id === id) as Leader | undefined;
//               return { lead };
//             },
//         },
//         subscribe: {
//             response: ({ leadId, userId }) => {
//               console.log('SUB')
//               mockedUsers = mockedUsers.map(user => {
//                 if (leadId === user.id) {
//                   user.subscribers.push(Math.floor(Math.random() * (1000 - 1 + 1)) + 1);
//                 }

//                 return user;
//               })
//             },
//         },
//         unsubscribe: {
//             response: ({ leadId, userId }) => {
//               console.log('UN')
//               mockedUsers = mockedUsers.map(user => {
//                 if (leadId === user.id) {
//                   user.subscribers.pop();
//                 }

//                 return user;
//               })
//             },
//         },
//         checkSubscribe: {
//           response: ({ leadId, userId }) => {
//             const seerch = mockedUsers.find((moc) => moc.id === leadId)
//             if (!seerch) {
//               return {isSubscribed:false};
//             }
//             if (seerch.id === mockedUsers[0].id) {
//               return {isSubscribed: seerch.subscribers.includes(100)};
//             }
//             if (seerch.id === mockedUsers[1].id) {
//               return {isSubscribed: seerch.subscribers.includes(1000)};
//             }
//             return {isSubscribed:false};
//           }
//         },
//         booking: {
//           delay: 3000,
//           response: ({ tgId, eventId, leadTgId }) => {
//             const checkBooking = Math.floor(Math.random() * 3);
//             if (checkBooking === 0) {
//               return {
//                 success: true,
//                 message: 'Забронировано'
//               };
//             }

//             if (checkBooking === 1) {
//               return {
//                 success: false,
//                 message: 'Уже было забронировано'
//               };
//             }

//             return {
//               success: false,
//               message: 'Какая-то ошибка'
//             }
//           }
//         }
//     }
// });

const api = routesConfig.build();

export { api };
