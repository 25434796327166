import React, { useState, useEffect, useRef } from "react";
import { Card, Flex, ScrollArea, SimpleGrid } from "@mantine/core";
import { useInfiniteQuery } from "react-query";
import "./dwqdwqdqw.css";
import { Leader, api } from "../../models/api";
import type { CalendarComponent } from "./calendar.types";
import { getDateWithPadding, getDiffMinutes } from "./calendar.utils";
import moment from "moment";

interface Event {
  id: string;
  name: string;
}

interface Day {
  date: Date;
  event: any;
}

const DAY_HEIGHT = 120;
const PAGE_SIZE = 33;

const CalendarComponenttt = ({
  leader,
}: {
  leader: Leader;
  subscriber: {
    id: number;
    username: string;
  };
}) => {
  const viewportHeight = useRef<HTMLDivElement>(null);
  const viewportWidth = useRef<HTMLDivElement>(null);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [prevScrollWidth, setPrevScrollWidth] = useState(0);
  const [isInitialScrollDone, setIsInitialScrollDone] = useState(false);

  const generateDaysWithEvents = (
    startDate: Date,
    count: number,
    direction: "past" | "future",
    events: CalendarComponent[] = []
  ) => {
    const newDays: Day[] = [];
    for (let i = 0; i < count; i++) {
      const nextDay = new Date(startDate);
      nextDay.setDate(
        startDate.getDate() + (direction === "future" ? i + 1 : -(i + 1))
      );

      const eventForDay = events.find(
        (event) =>
          new Date(event.start ?? "").toDateString() === nextDay.toDateString()
      );

      newDays.push({
        date: nextDay,
        event: eventForDay
          ? { id: eventForDay.uid, name: eventForDay.summary }
          : null,
      });
    }

    return direction === "future" ? newDays : newDays.reverse();
  };

  const scrollToToday = () => {
    if (viewportHeight.current) {
      const todayElement = viewportHeight.current.querySelector(
        `[data-date="${new Date().toDateString()}"]`
      );

      if (todayElement) {
        const todayPosition = todayElement.getBoundingClientRect().y;
        const viewportTop = viewportHeight.current.getBoundingClientRect().y;

        if (todayPosition > viewportTop) {
          viewportHeight.current.scrollTo(0, todayPosition - viewportTop);
          viewportHeight.current.tabIndex = 0;
        }
      }
    }

    if (viewportWidth.current) {
      const todayElement = viewportWidth.current.querySelector(
        `[data-date="${new Date().toDateString()}"]`
      );

      if (todayElement) {
        const todayPosition = todayElement.getBoundingClientRect().x;
        const viewportBottom = viewportWidth.current.getBoundingClientRect().x;

        if (todayPosition > viewportBottom) {
          viewportWidth.current.scrollTo(todayPosition, 0);
          viewportWidth.current.tabIndex = 0;
        }
      }
    }
  };

  const getInit = () => {
    const base = leader?.calendar ?? [];
    const today = new Date();
    const start = generateDaysWithEvents(today, PAGE_SIZE, "past", base);
    start.push({ date: today, event: null });

    const initialDays = start.concat(
      generateDaysWithEvents(today, PAGE_SIZE - 1, "future", base)
    );

    const past = initialDays.slice(0, initialDays.length / 2);
    const future = initialDays.slice(initialDays.length / 2);

    const initialData = {
      pages: [past, future],
      pageParams: [
        { date: past[0], direction: "past" },
        { date: future[future.length - 1], direction: "future" },
      ],
    };
    return initialData;
  };

  useEffect(() => {
    if (!isInitialScrollDone) {
      scrollToToday();
      setIsInitialScrollDone(true);
    }
  }, []);

  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useInfiniteQuery(
    "calendarDays",
    async ({ pageParam = { date: new Date(), direction: "past" } }) => {
      const { date, direction } = pageParam;

      const events = await api.user.getLeadEvents({
        id: leader.id,
        timeStart:
          direction === "past"
            ? getDateWithPadding(date, -PAGE_SIZE).toISOString()
            : new Date(date).toISOString(),
        timeEnd:
          direction === "future"
            ? getDateWithPadding(date, PAGE_SIZE).toISOString()
            : new Date(date).toISOString(),
      });

      return generateDaysWithEvents(date, PAGE_SIZE, direction, events);
    },
    {
      initialData: getInit(),
      getNextPageParam: (lastPage) => ({
        date: lastPage[lastPage.length - 1].date,
        direction: "future",
      }),
      getPreviousPageParam: (firstPage) => ({
        date: firstPage[0].date,
        direction: "past",
      }),
    }
  );

  const updateTopDateByHeight = () => {
    if (
      viewportHeight.current &&
      data?.pages &&
      viewportHeight.current.tabIndex === -1
    ) {
      const centerY = viewportHeight.current.offsetHeight;
      const dayElements =
        viewportHeight.current.querySelectorAll(".calendar-day");

      const blaa = Array.from(dayElements);
      for (const dayElement of blaa) {
        const rect = dayElement.getBoundingClientRect();

        const elementCenterY = rect.top - rect.height;

        if (
          elementCenterY >= centerY - DAY_HEIGHT / 2 &&
          elementCenterY <= centerY + DAY_HEIGHT / 2
        ) {
          const dateString = dayElement.getAttribute("data-date");
          if (dateString) {
            if (viewportWidth.current) {
              const todayElement = viewportWidth.current.querySelector(
                `[data-date="${dateString}"]`
              );
              if (todayElement) {
                const todayPosition = todayElement.getBoundingClientRect().x;
                const viewportBottom =
                  viewportWidth.current.getBoundingClientRect().x;

                viewportWidth.current.scrollLeft +=
                  todayPosition - viewportBottom;
                viewportWidth.current.tabIndex = 0;
              }
            }
          }
        }
      }
    }
  };

  const updateTopDateByWidth = () => {
    if (
      viewportWidth.current &&
      data?.pages &&
      viewportWidth.current.tabIndex === -1
    ) {
      const centerW = viewportWidth.current.offsetWidth / 2 - DAY_HEIGHT;
      const dayElements =
        viewportWidth.current.querySelectorAll(".calendar-day");

      for (const dayElement of Array.from(dayElements)) {
        const rect = dayElement.getBoundingClientRect();

        const elementCenterX = rect.left + rect.width / 2;

        if (
          elementCenterX >= centerW - DAY_HEIGHT / 2 &&
          elementCenterX <= centerW + DAY_HEIGHT / 2
        ) {
          const dateString = dayElement.getAttribute("data-date");
          if (dateString) {
            if (viewportHeight.current) {
              const todayElement = viewportHeight.current.querySelector(
                `[data-date="${dateString}"]`
              );

              if (todayElement) {
                const todayPosition = todayElement.getBoundingClientRect().top;
                const viewportBottom =
                  viewportHeight.current.getBoundingClientRect().top;

                viewportHeight.current.scrollTop +=
                  todayPosition - viewportBottom;
                viewportHeight.current.tabIndex = 0;
              }
            }
          }
        }
      }
    }
  };

  const onHeightScrollPositionChange = () => {
    updateTopDateByHeight();
    if (viewportHeight.current && viewportHeight.current.tabIndex === -1) {
      const { scrollTop, scrollHeight, clientHeight } = viewportHeight.current;
      if (scrollTop === 0 && hasPreviousPage && !isFetchingPreviousPage) {
        setPrevScrollHeight(scrollHeight);
        fetchPreviousPage();
      } else if (
        scrollTop + clientHeight >= scrollHeight &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    }
    if (viewportHeight?.current?.tabIndex === 0) {
      viewportHeight.current.tabIndex = -1;
    }
  };

  const onWidthScrollPositionChange = () => {
    updateTopDateByWidth();
    if (viewportWidth.current && viewportWidth.current.tabIndex === -1) {
      const { scrollLeft, scrollWidth, clientWidth } = viewportWidth.current;

      if (scrollLeft === 0 && hasPreviousPage && !isFetchingPreviousPage) {
        setPrevScrollWidth(scrollWidth);
        fetchPreviousPage();
      } else if (
        scrollLeft + clientWidth >= scrollWidth &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    }
    if (viewportWidth?.current?.tabIndex === 0) {
      viewportWidth.current.tabIndex = -1;
    }
  };

  useEffect(() => {
    if (viewportHeight.current && isFetchingPreviousPage === false) {
      const newScrollHeight = viewportHeight.current.scrollHeight;

      if (prevScrollHeight > 0 && newScrollHeight > prevScrollHeight) {
        viewportHeight.current.scrollTop += newScrollHeight - prevScrollHeight;
      }
    }
  }, [data, isFetchingPreviousPage, prevScrollHeight]);

  useEffect(() => {
    if (viewportWidth.current && isFetchingPreviousPage === false) {
      const newScrollWidth = viewportWidth.current.scrollWidth;

      if (prevScrollWidth > 0 && newScrollWidth > prevScrollWidth) {
        viewportWidth.current.scrollLeft += newScrollWidth - prevScrollWidth;
      }
    }
  }, [data, isFetchingPreviousPage, prevScrollWidth]);

  return (
    <div>
      <ScrollArea
        h={DAY_HEIGHT}
        w={420}
        viewportRef={viewportWidth}
        offsetScrollbars={"x"}
        onScrollPositionChange={onWidthScrollPositionChange}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            height: "100%",
          }}
        >
          {data?.pages.flat().map((day, index) => (
            <div
              key={day.event?.id ?? index}
              className="calendar-day"
              data-date={day.date.toDateString()}
              style={{
                height: DAY_HEIGHT,
                width: DAY_HEIGHT,
                flexShrink: 0,
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid #ddd",
              }}
            >{`${day.date.getDate()}.${day.date.getMonth() + 1}`}</div>
          ))}
        </div>
      </ScrollArea>

      <ScrollArea
        h={420}
        viewportRef={viewportHeight}
        onScrollPositionChange={onHeightScrollPositionChange}
      >
        {data?.pages.flat().map((day, index) => (
          <div
            key={day.event?.id ?? index}
            className="calendar-day"
            data-date={day.date.toDateString()}
            style={{ height: DAY_HEIGHT }}
          >
            <div>{day.date.toDateString()}</div>
            {day.event ? (
              <ul>
                <Card
                  key={index}
                  m={5}
                  style={{ borderRadius: 15 }}
                  h={60}
                  p={5}
                >
                  <SimpleGrid cols={3} spacing={0}>
                    <div style={{ fontWeight: "bold", alignContent: "center" }}>
                      {moment(day.event.start).format("HH:mm")}
                    </div>
                    <Flex direction={"column"}>
                      <div style={{ fontWeight: "bold" }}>Событие</div>
                      <div style={{ fontWeight: "bold" }}>
                        ({getDiffMinutes(day.event.start, day.event.end)} мин)
                      </div>
                    </Flex>
                    <div style={{ fontWeight: "bold", alignContent: "center" }}>
                      {day.event.cost ? `${day.event.cost}р.` : "Без цены"}
                    </div>
                  </SimpleGrid>
                </Card>
              </ul>
            ) : (
              <div>No event</div>
            )}
          </div>
        ))}
        {isFetchingNextPage || isFetchingPreviousPage ? (
          <div>Loading more days...</div>
        ) : null}
      </ScrollArea>
    </div>
  );
};

export default CalendarComponenttt;
