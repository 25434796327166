import "./App.css";
import { useEffect } from "react";
import { useTelegram } from "./hooks/useTelegram";
import Header from "./components/Header/Header";
import { Route, Routes } from "react-router-dom";
import { TagsComponent } from "./components/Tags/tags.component";
import Form from "./components/Form/Form";
import UserPage from "./pages/user/user.component";
import Catalog from "./components/Catalog/catalog";
import PostPage from "./pages/post/post.component";
import AccountPage from "./pages/account/account.component";
import { QueryClient, QueryClientProvider } from 'react-query';


function App() {
  const { onToggleButton, tg } = useTelegram();
  const queryClient = new QueryClient();

  useEffect(() => {
    tg.ready();
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Header />
        <Routes>
          <Route index element={<TagsComponent />} />
          <Route path={"form"} element={<Form />} />
          <Route path={"user"} element={<UserPage />} />
          <Route path={"post"} element={<PostPage />} />
          <Route path={"account"} element={<AccountPage />} />
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;
